import selectDeliveryType from "src/redux/cart/general/selectDeliveryType";
import { useAppSelector } from "src/redux/hooks";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import useSWR from "swr";

const DEFAULT_PRODUCTS_COUNT = 37412;

export const useProductsCount = (): number => {
  const { fetchConfig } = useAppConfig();
  const storeId = useAppSelector(s => s.storeManagement.store.id);
  const deliveryType = useAppSelector(selectDeliveryType);

  const { data } = useSWR([storeId, deliveryType],
    getProductsCount,
    {
      fallbackData: { total: DEFAULT_PRODUCTS_COUNT },
      revalidateOnFocus: false,
     },
  );

  return data.total;

  async function getProductsCount(): Promise<{ total: number }> {
    const { xChain, xVersion, apiRoot } = fetchConfig;

    const response = await fetch(
      `${apiRoot}/stores/${storeId}/products_count/`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-chain': xChain,
          'x-version': xVersion,
          'X-Delivery-Type': deliveryType,
        },
      },
    );

    return await response.json();
  }
};
