import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';
import selectSelectedDelivery from './selectSelectedDelivery';

const selectIsAddressDeliveryEmpty: Selector<RootState, boolean> = createSelector(
  [
    selectSelectedDelivery,
    s => s.addressManagement.addressIsChecked,
  ],
  (delivery, addressIsChecked) => {
    if (!addressIsChecked) {
      return false;
    }

    return !delivery || Object.keys(delivery.address).length === 0;

  },
);

export default selectIsAddressDeliveryEmpty;
