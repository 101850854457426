import dynamic from 'next/dynamic';
import { B2BProductsSlider, CategoriesMainSlider, PromotionsCarousel } from 'src/modules/Categories';
import MainPageWidgetsPanel from 'src/modules/MainPageWidgetsPanel';
import { RecommendationsBanner } from 'src/modules/RecommendationsModal';
import { useAppSelector } from 'src/redux/hooks';
import useLocalization from 'src/services/localization/useLocalization';
import useToggles from 'src/services/toggles/useToggles';
import useBanners from 'src/swr/useBanners/useBanners';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import GeneralPagesLayout from '../../../layouts/GeneralPagesLayout';
import { Banner, Product } from '../../../redux/apiTypes';
import { addToCartButtonsEnum, impressionListEnum } from '../../../utils/marketing/enhancedEcommerce';
import getOrganizationMicroMarking from '../../../utils/marketing/microMarking/getOrganizationMicroMarking';
import getSearchWindowMicroMarking from '../../../utils/marketing/microMarking/getSearchWindowMicroMarking';
import Container from '../../views/Container/Container';
import MainPageHead from '../../views/MainPageHead/MainPageHead';
import MainSlider from '../../views/MainSlider/MainSlider';
import PageWrap from '../../views/PageWrap/PageWrap';
import PageWrapAside from '../../views/PageWrapAside/PageWrapAside';
import PageWrapBody from '../../views/PageWrapBody/PageWrapBody';
import Responsive from '../../views/Responsive/Responsive';
import ScriptLdJson from '../../views/ScriptLdJson/ScriptLdJson';
import Benefits from '../Benefits/Benefits';
import EmptyCartWatcher from '../EmptyCartWatcher/EmptyCartWatcher';
import LastViewProductsSlider from '../LastViewProductsSlider/LastViewProductsSlider';
import PopularCategoriesSliders from '../PopularCategoriesSliders/PopularCategoriesSliders';
import SpecialProductsSlider from '../SpecialProductsSlider/SpecialProductsSlider';


const OrderInfoBar = dynamic(
  () => import('../OrderInfoBar/OrderInfoBar'),
  { ssr: false },
);

const NewYearAnimation = dynamic(
  () => import('../NewYear/NewYearAnimation'),
  { ssr: false },
);

export interface Props {
  bannersSsr: null | Banner[];
  promotionsSsr: Product[];
}

export function MainPage(props: Props) {
  const { bannersSsr, promotionsSsr } = props;

  const localize = useLocalization();
  const { country } = useAppConfig();

  const userIsLoggedIn = useAppSelector(s => s.user.isLoggedIn);
  const language = useAppSelector(s => s.app.language);
  const baseURL = useAppSelector(s => s.app.baseURL);
  const storeInfo = useAppSelector(s => s.storeManagement.store);
  const retailChain = useAppSelector(s => s.retailChain.data);

  const { NEW_YEAR_AVAILABLE } = useToggles();

  const {
    data: bannersSwrData,
    errors: bannersErrors,
    isFetching: isFetchingBanners,
  } = useBanners({
    place: storeInfo.id ? 'home_page_for_store' : 'home_page',
    storeId: storeInfo.id,
    canFetch: !bannersSsr,
  });
  const banners = bannersSsr || bannersSwrData;
  const bannersHasError = !!bannersErrors?.length;

  const countryText = localize('chain.country');

  const searchWindowMicroMarking = getSearchWindowMicroMarking(baseURL);

  const organizationMicroMarking = getOrganizationMicroMarking({
    baseURL,
    storeInfo,
    retailChain,
    countryText,
    country,
  });

  return (
    <GeneralPagesLayout fixedMenu withNotifyGamify>
      {NEW_YEAR_AVAILABLE &&
        <NewYearAnimation />
      }
      <MainPageHead />
      <Container>
        <PageWrap>
          <Responsive desktop displayNoneMode>
            <PageWrapAside />
          </Responsive>
          <PageWrapBody>
            {userIsLoggedIn &&
              <OrderInfoBar />
            }
            <MainSlider
              place='home'
              language={language}
              banners={banners}
              isFetching={isFetchingBanners}
              hasError={bannersHasError}
            />
            <Benefits />
            <MainPageWidgetsPanel />
            <SpecialProductsSlider />
            <PromotionsCarousel
              place={impressionListEnum.MAIN_PAGE_PROMOTIONS}
              handlersPlace={addToCartButtonsEnum.MAIN_PAGE_PROMOTIONS}
              initProducts={promotionsSsr}
            />
            <CategoriesMainSlider />
            <B2BProductsSlider />
            <PopularCategoriesSliders
              place={impressionListEnum.MAIN_PAGE_POPULAR_PRODUCTS}
            />
            {userIsLoggedIn && (
              <LastViewProductsSlider />
            )}

            <RecommendationsBanner />
          </PageWrapBody>
        </PageWrap>
      </Container>
      <ScriptLdJson body={organizationMicroMarking} />
      <ScriptLdJson body={searchWindowMicroMarking} />
      <EmptyCartWatcher />
    </GeneralPagesLayout>
  );
}

export default MainPage;
